import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

import {
  Box, Text, Button,
} from 'grommet';

import {
  Seo, Spinning, AppButton,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { localAsset } from '@Helpers/asset';
import {
  initiateCyclopsCompanyOrganizationsRequest,
  setCyclopsActiveOrganizationRequest,
  submitNewCyclopsOrganizationRequest,
  editCyclopsOrganizationRequest,
  deleteCyclopsOrganizationRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import { FormInputSelector } from '../../../Partial/Product/Cyclops/CyclopsComponents';
import {
  StyledCyclopsHeading, StyledCyclopsContainer, StyledSVG, StyledHoverButton,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const CyclopsOrganizationsPage = ({
  small,
  loading,
  mixpanel,
  location,
  fetchCompanyOrganizations,
  setActiveOrganization,
  submitNewOrganization,
  editOrganization,
  deleteOrganization,
  companyOrganizations = null,
  activeOrganization = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
}) => {
  const navigate = useNavigate();
  const [showOrganizationForm, setShowOrganizationForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [resetOrganizations, setResetOrganizations] = React.useState(false);
  const [activeOrgId, setActiveOrgId] = React.useState(null);
  /* eslint-disable camelcase */
  const [formValues, setFormValues] = React.useState({
    inputValues: {
      name: '',
      piracy_domains: '',
      encoder_outbound_protocol: null,
      encoder_outbound_video_codec: null,
      encoder_outbound_audio_codec: null,
      cyclops_starts_post_abr: false,
    },
  });

  React.useEffect(() => {
    if (!companyOrganizations || resetOrganizations) {
      fetchCompanyOrganizations();

      if (resetOrganizations) setResetOrganizations(false);
    }
  }, [resetOrganizations]);

  const handleFormValues = (key, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      inputValues: {
        ...prevState.inputValues,
        [key]: value,
      },
    }));
  };

  const handleResetForm = () => {
    setFormValues({
      inputValues: {
        name: '',
        piracy_domains: '',
        encoder_outbound_protocol: null,
        encoder_outbound_video_codec: null,
        encoder_outbound_audio_codec: null,
        cyclops_starts_post_abr: false,
      },
    });
  };

  const handleEditOrganization = (orgId) => {
    const organization = companyOrganizations.find(({ Id }) => Id === orgId);
    let piracyDomains = '';
    if (organization.piracyDomains && organization.piracyDomains.length > 0) {
      piracyDomains = organization.piracyDomains.join(', ');
    }

    setFormValues({
      inputValues: {
        name: organization.name,
        piracy_domains: piracyDomains,
        encoder_outbound_protocol: organization.encoderOutboundProtocol,
        encoder_outbound_video_codec: organization.encoderOutboundVideoCodec,
        encoder_outbound_audio_codec: organization.encoderOutboundAudioCodec,
        cyclops_starts_post_abr: organization.cyclopsStartsPostAbr,
        id: organization.Id,
      },
    });
    setActiveOrgId(orgId);
    setIsEditing(true);
    setShowOrganizationForm(true);
  };

  const handleDeleteOrganization = (orgId) => {
    setIsEditing(false);
    setShowOrganizationForm(false);
    setResetOrganizations(true);
    deleteOrganization({ Id: orgId }, paths.cyclopsOrganizations);
  };

  const submitFormValues = () => {
    if (isEditing) {
      editOrganization(activeOrgId, formValues.inputValues, paths.cyclopsOrganizations);
    } else {
      submitNewOrganization(formValues.inputValues, paths.cyclopsOrganizations);
    }

    setIsEditing(false);
    setShowOrganizationForm(false);
    setResetOrganizations(true);
  };

  const handleEventsRedirect = (orgId, orgName) => {
    if (!activeOrganization || activeOrganization !== orgId) {
      setActiveOrganization({ uid: orgId, name: orgName });
    }
    navigate(paths.cyclopsEventsList.replace(':status', 'live'));
  };

  const {
    darkModeColors: {
      primaryDarkBg, primaryText, secondaryText, containerBg, menuButtonHighlight,
    },
  } = authPagesConfig;

  const requiredKeys = Object.keys(formValues.inputValues).filter((key) => key !== 'piracy_domains');
  const submitDisabled = requiredKeys.some((key) => formValues.inputValues[key] === '' || formValues.inputValues[key] === null);

  const renderPageContents = (showForm) => {
    if (showForm) {
      return (
        <Box width="100%" justify="center" pad={{ right: small ? '0' : '15%' }} gap="1rem">
          <FormInputSelector
            small={small}
            label="Name"
            renderKey="text"
            inputKey="name"
            inputType="text"
            value={formValues.inputValues.name}
            placeholder="Name of this organization"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Piracy domains"
            renderKey="text"
            inputKey="piracy_domains"
            inputType="text"
            value={formValues.inputValues.piracy_domains}
            placeholder="Domains to monitor, seperated by comma"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound protocol"
            renderKey="dropdown"
            inputKey="encoder_outbound_protocol"
            value={formValues.inputValues.encoder_outbound_protocol}
            handleFormValues={handleFormValues}
            dropdownOptions={['RTMP', 'HLS', 'RTP', 'SRT', 'RIST']}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound video codec"
            renderKey="dropdown"
            inputKey="encoder_outbound_video_codec"
            value={formValues.inputValues.encoder_outbound_video_codec}
            handleFormValues={handleFormValues}
            dropdownOptions={['h264', 'h265', 'vp8', 'vp9']}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Encoder outbound audio codec"
            renderKey="dropdown"
            inputKey="encoder_outbound_audio_codec"
            value={formValues.inputValues.encoder_outbound_audio_codec}
            handleFormValues={handleFormValues}
            dropdownOptions={['aac', 'mp3']}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Cyclops starts post ABR"
            renderKey="checkbox"
            inputKey="cyclops_starts_post_abr"
            value={formValues.inputValues.cyclops_starts_post_abr}
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <Box pad={{ bottom: '1rem' }} justify="center" align="end">
            <AppButton
              overrideHover
              disabled={submitDisabled}
              level="dynamicLarge"
              color="white"
              width="7.5rem"
              bgColor={authPagesConfig.buttonHighlight}
              onClick={() => submitFormValues()}
              fontWeight={600}
              label="Save"
            />
          </Box>
        </Box>
      );
    }
    /* eslint-enable camelcase */

    if (companyOrganizations?.length > 0) {
      return (
        <Box direction="column" background={containerBg} round="10px" pad={{ horizontal: '1.25rem' }}>
          {companyOrganizations.map(({ name, Id }, i) => (
            <Box
              key={Id}
              width="100%"
              direction="row"
              justify="between"
              align="center"
              pad={{ vertical: '1.25rem' }}
              border={(i !== companyOrganizations.length - 1) && {
                side: 'bottom', size: '1px', style: 'solid', color: '#394658',
              }}
            >
              <Text size="1rem" weight={600} color={primaryText}>
                {name}
              </Text>
              <Box direction="row" gap="0.5rem">
                <StyledHoverButton
                  plain
                  onClick={() => handleEditOrganization(Id)}
                  hoverColor={menuButtonHighlight}
                >
                  <Box direction="row" gap="0.5rem" align="center">
                    <StyledSVG
                      src={localAsset('images/edit-pencil-icon.svg')}
                      height={small ? '1.1rem' : '1rem'}
                      width={small ? '1.1rem' : '1rem'}
                      style={{ paddingTop: '0.15rem' }}
                      $fillColor="white"
                    />
                    <Text size="1rem" color={primaryText}>Edit</Text>
                  </Box>
                </StyledHoverButton>
                <StyledHoverButton
                  plain
                  onClick={() => handleEventsRedirect(Id, name)}
                  hoverColor={menuButtonHighlight}
                >
                  <Box direction="row" gap="0.5rem" align="center">
                    <StyledSVG
                      src={localAsset('images/calendar-icon.svg')}
                      height={small ? '1.1rem' : '1rem'}
                      width={small ? '1.1rem' : '1rem'}
                      style={{ paddingTop: '0.15rem' }}
                      $fillColor="white"
                    />
                    <Text size="1rem" color={primaryText}>Event schedule</Text>
                  </Box>
                </StyledHoverButton>
              </Box>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <StyledCyclopsContainer flex="horizontal" justify="center" background={containerBg}>
        <Box pad={{ vertical: '1.25rem' }}>
          {loading ? (
            <Box flex justify="center" align="center">
              <Spinning size="large" color={authPagesConfig.buttonHighlight} />
            </Box>
          ) : (
            <Text size="1rem" color={secondaryText}>No organizations created yet.</Text>
          )}
        </Box>
      </StyledCyclopsContainer>
    );
  };

  const pageTitle = showOrganizationForm ? 'Create an Organization' : 'Organizations';
  const buttonTitle = showOrganizationForm ? 'Go Back' : 'Create Organization';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={{ horizontal: small ? '2rem' : '3rem', bottom: small ? '2rem' : '3rem', top: small ? '3rem' : '4rem' }}
        direction="column"
        gap="1.5rem"
      >
        <Box direction="column" gap="0.5rem">
          <Box width="100%" direction="row" justify="between" align="center" pad={{ right: small ? '0' : '3rem' }}>
            <StyledCyclopsHeading noMargin level={2} color={primaryText}>
              {pageTitle}
            </StyledCyclopsHeading>
            <AppButton
              overrideHover
              level="dynamicLarge"
              color="white"
              width="12rem"
              bgColor={authPagesConfig.buttonHighlight}
              onClick={() => {
                setIsEditing(false);
                setActiveOrgId(null);
                handleResetForm();
                setShowOrganizationForm(!showOrganizationForm);
              }}
              fontWeight={600}
              label={buttonTitle}
            />
          </Box>
          {isEditing && (
            <Box direction="column" pad={{ bottom: '1rem' }}>
              <Button plain onClick={() => handleDeleteOrganization(activeOrgId)}>
                <Box direction="row" gap="0.5rem" align="center">
                  <StyledSVG
                    src={localAsset('images/delete-content-icon.svg')}
                    height={small ? '1.1rem' : '1rem'}
                    width={small ? '1.1rem' : '1rem'}
                    style={{ paddingTop: '0.15rem' }}
                    $fillColor={secondaryText}
                  />
                  <Text size="1rem" color={secondaryText}>Delete Organization</Text>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
        <Box pad={{ right: small ? '0' : '3rem' }}>
          {renderPageContents(showOrganizationForm)}
        </Box>
      </Box>

    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyOrganizations: state.cyclops.companyOrganizations,
    activeOrganization: state.cyclops.activeCompanyOrganization,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanyOrganizations: initiateCyclopsCompanyOrganizationsRequest,
    setActiveOrganization: setCyclopsActiveOrganizationRequest,
    submitNewOrganization: submitNewCyclopsOrganizationRequest,
    editOrganization: editCyclopsOrganizationRequest,
    deleteOrganization: deleteCyclopsOrganizationRequest,
  }, dispatch);
}

CyclopsOrganizationsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    piracyDomains: PropTypes.arrayOf(PropTypes.string),
    encoderOutboundProtocol: PropTypes.string,
    encoderOutboundVideoCodec: PropTypes.string,
    encoderOutboundAudioCodec: PropTypes.string,
    cyclopsStartsPostAbr: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  })),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  fetchCompanyOrganizations: PropTypes.func.isRequired,
  setActiveOrganization: PropTypes.func.isRequired,
  submitNewOrganization: PropTypes.func.isRequired,
  editOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(CyclopsOrganizationsPage),
);
